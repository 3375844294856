import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FurtherReading from "../components/furtherReading"

const WashFrequency = () => (
  <Layout>
    <SEO
      title="How frequently you should wash natural hair"
      desciption="You have probably heard people telling you not to wash too frequently but this is meaningless advice without understanding why not to do it and how frequent is too frequent?"
    />
    <h1>How Frequently You Should Wash Natural Hair</h1>

    <p>Everyone has their own opinion on how frequently you should wash your hair. The truth is that everyone has different experiences, some people can get away with washing their hair more frequently and others can wash a lot less and see no problems. You are probably reading this because something isn’t working right for you and you might suspect that how frequently you wash your hair might be part of the problem.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/VSnmWDZ.jpg"
        alt="how often you should wash your hair"
        style={{marginBottom: "5px"}}
      />
      <p style={{fontSize: "0.9rem", textAlign:"left", color:"grey"}}>Photo credit: <a
        href="https://unsplash.com/@delaneyvan"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration:"none", color:"inherit" }}
      >Delaney Van</a></p>
    </div>

    <p>On one side of the argument, if you don’t wash your hair enough, the build up from any products you use and the environment can make for an uncomfortable experience. At the very  least you will feel dirty then you will start to see a difference. Your hair will probably lose any shine and start to look matte. It might even get harder to comb without adding extra products which will add to the problem of feeling unclean.</p>
    <p>Keep going and you might notice a buildup of product and dead skin on your scalp along with spots around your hairline because the oils are blocking your pores and causing a problem for your skin.</p>
    <p>It doesn’t take much to imagine all the negatives that come along with leaving too much time between washes and most of us agree on those but what happens when you wash too frequently?</p>
    <p>You have probably heard people telling you not to wash too frequently but this is meaningless advice without understanding why not to do it and how frequent is too frequent?</p>

    <h2>The Answer</h2>
    <p>Again, there is no definitive answer. How often you wash your hair depends on a few things. But that’s not what you want to hear is it? And that would be the easy way out for me. To help answer the question, I’ll give an answer based on two of my own routines. Each one is from a different point in my life based on what length my hair was and how I styled it.</p>
    <p>Here’s a little bit about my lifestyle that should give some insight. I am reasonably active and go to the gym a few times a week, each time I go I work hard and sweat which is visible around my edges. I usually shower quickly but if I take a while the steam builds up and makes the surface of my hair slightly wet. Every time moisture from sweat or steam touches my hair it affects the state of it which will make sense later.</p>

    <h2>Long Hair/ Styles</h2>
    <p>I would have my hair styled in corn rows or twists. I would keep the twists in up to 3 weeks and the cornrows lasted around 2. When the roots start blending together, I start to feel messy so I take them out and that’s when I wash my hair.  Most of the time I would do 6 big plaits as I added conditioner then get my hair done the same day or the next.</p>
    <p>It was rare for me to feel dirty unless I went to the gym more than usual meaning more days of sweating. That’s pretty much the only variable that would make any difference. Sweating more frequently would make the style get messy much quicker.</p>

    <h2>Short hair/ not styled</h2>
    <p>Since I’ve cut my hair shorter and don’t style it in braids, twists or corn rows anymore, my attitude towards washing has changed a lot. Before, my washes would fit between getting my hair done for convenience. Now, I can wash when I want to. I would say the average is around 10 days. It takes far less time because there is a lot less hair to deal with so I can do it during the week instead of waiting for the weekend when I have less commitments.</p>
    <p>I don’t generally wait for my hair to feel or look dirty but there have been times when I simply don’t want to add any more water or leave in conditioner to help detangle it. When I wash my hair, I add conditioner and oils to help replace the lost nutrients.</p>
    <p>I also wash with a shampoo that isn’t harsh so it doesn’t feel like everything is being stripped away like previous shampoos have done. It’s not for everyone because my hair does feel very slightly greasy after a wash but I prefer it and my hair feels better for it.</p>

    <h2>Summary</h2>
    <p>I personally wash anywhere from 1 week to 3 week depending on the length and state of my hair. I know people using all sorts of different schedules and their hair looks good. If you aren’t sure your schedule is working for you, tweak it and see if you notice a difference. The last thing to note is that it might not be the time period between washes at all and could be to do with the shampoo you use or something else you might be doing.</p>


    {/* <a
        href="https://example.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Example Link - for affiliate, add nofollow
      </a> */}



      <FurtherReading
      content={
        <div>
        <p>
            <Link to="/afro-hair-products/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
             The Best Afro Hair Products
            </Link>{" "}
            - The complete list of products you need for every step in your hair care routine...
          </p>  
        <p>
            <Link to="/14-tips-on-how-to-grow-afro-hair/" style={{ textDecoration: "none", color:"Orchid", fontWeight:"600" }}>
            14 tips on how to grow afro hair
            </Link>{" "}
            - Growing afro hair can be one of the most annoying things in the world, the good news is that growing natural doesn't have to be hard. Read our 14 tips to make your life easier here...
          </p>             
        </div>
      }
    />

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WashFrequency
